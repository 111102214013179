import { provide, inject, InjectionKey } from 'vue';
import { unit, Config } from '@/configs/app';

const injectionKey = Symbol('appConfig') as InjectionKey<Config>;

export function provideAppConfig(config: Config): void {
  return provide(injectionKey, config);
}

/**
 * Composition function that returns Config
 */
function useAppConfig(): Config {
  return inject(injectionKey, unit);
}

export default useAppConfig;
