import Vue, { Ref, watch } from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import { Logger } from '@/services/logger';
import { en } from '@/i18n/en';
import { ja } from '@/i18n/ja';
import dayjs from 'dayjs';
import * as errors from './errors';

export { errors };

export type Config = {
  logger: Logger;
  locale: Ref<string>;
};

export function configureI18n(config: Config): VueI18n {
  Vue.use(VueI18n);
  dayjs.locale(config.locale.value);

  const i18n = new VueI18n({
    locale: config.locale.value,
    messages: {
      en,
      ja,
    },
    missing: (locale, key) => {
      config.logger({
        level: 'error',
        message: new errors.MissingTranslationError({ locale, key }),
      });
    },
  });

  watch(config.locale, (value) => {
    if (!i18n.availableLocales.includes(value)) throw new Error(`Locale '${value}' not found`);

    i18n.locale = value;
    dayjs.locale(value);
  });

  return i18n;
}

export type TranslateFn = {
  (key: VueI18n.Path, value?: VueI18n.Values): string;
  (key: VueI18n.Path, locale: VueI18n.Locale, value?: VueI18n.Values): string;
};

export function toTranslateFn(i18n: VueI18n): TranslateFn {
  /**
   * Wraps the translate function {@link VueI18n.t} to restore the previous behavior of always returning a string
   */
  return (key: VueI18n.Path, locale?: VueI18n.Locale | VueI18n.Values, value?: VueI18n.Values) => {
    const result = typeof locale === 'string' ? i18n.t(key, locale, value) : i18n.t(key, value);
    return typeof result === 'string' ? result : key;
  };
}

export function mergeLocaleMessages(i18n: VueI18n, messages: LocaleMessages): void {
  Object.entries(messages).forEach(([locale, messageObject]) => {
    i18n.mergeLocaleMessage(locale, messageObject);
  });
}
