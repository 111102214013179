import * as z from 'zod';
import * as Locale from '@/models/Locale';
import { getTransactionExportMaxMonths } from '@/utils/transactionExportUtil';

export const Config = z.object({
  nodeEnv: z.string().optional(),
  apiBaseUrl: z.string(),
  timezone: z.string(),
  transactionExportMaxMonths: z.number(),
  cognitoAwsRegion: z.string(),
  cognitoUserPoolId: z.string(),
  cognitoAppClientId: z.string(),
  cognitoHostedUiDomainPrefix: z.string(),
  defaultLocale: Locale.Locale,
  storageKeyLocale: z.string(),
});

export type Config = Readonly<z.TypeOf<typeof Config>>;

export const unit: Config = {
  apiBaseUrl: '',
  timezone: 'Asia/Tokyo',
  transactionExportMaxMonths: 0,
  defaultLocale: 'ja',
  storageKeyLocale: 'vue:i18n:locale',
  cognitoAwsRegion: '',
  cognitoUserPoolId: '',
  cognitoAppClientId: '',
  cognitoHostedUiDomainPrefix: '',
};

export const fromEnvUnsafe = (): Config => {
  return Config.parse({
    nodeEnv: process.env.NODE_ENV,
    apiBaseUrl: process.env.VUE_APP_API_BASE_URL,
    timezone: process.env.VUE_APP_TIMEZONE,
    transactionExportMaxMonths:
      process.env.VUE_APP_TRANSACTION_DATE_RANGE &&
      getTransactionExportMaxMonths(process.env.VUE_APP_TRANSACTION_DATE_RANGE),
    cognitoAwsRegion: process.env.VUE_APP_COGNITO_AWS_REGION,
    cognitoUserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    cognitoAppClientId: process.env.VUE_APP_COGNITO_APP_CLIENT_ID,
    cognitoHostedUiDomainPrefix: process.env.VUE_APP_COGNITO_HOSTED_UI_DOMAIN_PREFIX,
    defaultLocale: unit.defaultLocale,
    storageKeyLocale: unit.storageKeyLocale,
  });
};
