import * as app from '@/configs/app';

export type Config = {
  region: string;
  clientId: string;
  userPoolId: string;
  hostedUiDomainPrefix: string;
};

export const fromAppConfig = (config: app.Config): Config => {
  return {
    region: config.cognitoAwsRegion,
    clientId: config.cognitoAppClientId,
    userPoolId: config.cognitoUserPoolId,
    hostedUiDomainPrefix: config.cognitoHostedUiDomainPrefix,
  };
};
