var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "ml-4",
                      attrs: { icon: "", small: "", fab: "" },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_c("v-icon", [_vm._v("fas fa-language")])],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        [
          _c("v-subheader", [_vm._v(_vm._s(_vm.$t("mt.dashboard.language")))]),
          _c(
            "v-list-item-group",
            {
              attrs: { mandatory: "" },
              model: {
                value: _vm.languageMenuModel$,
                callback: function ($$v) {
                  _vm.languageMenuModel$ = $$v
                },
                expression: "languageMenuModel$",
              },
            },
            _vm._l(_vm.languageOptions, function (option) {
              return _c(
                "v-list-item",
                { key: option.value },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(_vm.$t(option.label))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }