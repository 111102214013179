import { computed, Ref } from 'vue';
import * as domRect from '@/utils/domRect';

/**
 * Composition function that returns a style object that offsets an element based
 * on the x and y position of a provided DOMRect. Mainly used for fixed elements.
 */
function useDomRectOffsetStyle(rect$: Ref<DOMRectReadOnly | null>): Ref<Record<string, string>> {
  return computed(() => (rect$.value ? domRect.toPaddingOffsetStyle(rect$.value) : {}));
}

export default useDomRectOffsetStyle;
