import { LocaleMessageObject } from 'vue-i18n';

export const en: LocaleMessageObject = {
  mt: {
    login: {
      'back-to-sign-in': 'Back to Login',
      'signed-in': {
        'signed-in': 'Logged In',
        'go-to-dashboard': 'Go To Dashboard',
      },
      'sign-in': {
        'sign-in-to-your-account': 'Log in to your account',
        username: 'Username',
        password: 'Password',
        'sign-in': 'Log In',
        'storage-unavailable-error':
          'Error: Your browser experienced an unexpected error. Please try restarting your browser and try again.',
        'storage-unavailable-subtext':
          'If the problem persists, please contact support and share the following error message:',
      },
      'required-change-password': {
        'password-requirements': 'Password Requirements',
        'minimum-characters': 'At least 8 characters',
        'contains-number': 'At least 1 number',
        'contains-special-character': 'At least 1 special character',
        'valid-special-characters': '（^$*.[]{}()?-"!@#%&/,><\':;|_~`+=)',
        'contains-lowercase-character': 'At least 1 lower case character',
        'contains-uppercase-character': 'At least 1 upper case character',
        'change-password': 'Change Password',
        'new-password': 'New Password',
        'confirm-new-password': 'Confirm New Password',
        'passwords-do-not-match': 'The passwords do not match',
        change: 'Change',
      },
      'totp-input': {
        'confirm-totp-code': 'Confirm One Time Password',
        'verification-code': 'One Time Password',
        confirm: 'Confirm',
      },
      'totp-setup': {
        title: 'Setting up One Time Password',
        'subtitle-register-secret-code': {
          body: 'Please {0} or {1} below into your authenticator app',
          qrCode: 'scan the QR Code',
          secretCode: 'enter the Secret Code',
        },
        'subtitle-enter-otp': 'Please enter the One Time Password displayed on your authenticator app',
        'step-register-secret-code': 'Register Secret Code',
        'step-enter-otp': 'Enter One Time Password',
        'field-secret-code': 'Secret Code',
        'enter-totp-code': 'Enter One Time Password',
        verify: 'Verify',
        'qr-code': 'QR Code',
        'totp-code-cannot-be-empty': 'One Time Password cannot be empty',
        'totp-code-too-short': 'One Time Password must be at least 6 characters long',
        'action-back-to-signin': 'Back to login',
        'action-previous-step': 'Back',
        'action-next-step': 'Next',
        'action-complete-setup': 'Complete setup',
      },
      errors: {
        'unexpected-error': 'An unexpected error has occurred. Please contact support',
      },
      exceptions: {
        'User does not exist': 'User does not exist',
        'Incorrect username or password': 'Incorrect username or password',
        'User is not confirmed': 'User is not confirmed',
        'User already exists': 'User already exists',
        'Invalid verification code provided, please try again': 'Invalid verification code provided, please try again',
        'Invalid password format': 'Invalid password format',
        'Account recovery requires verified contact information':
          'Account recovery requires verified contact information',
        'Invalid phone number format': 'Invalid phone number format',
        'An account with the given email already exists': 'An account with the given email already exists',
        'Username cannot be empty': 'Username cannot be empty',
        'Password attempts exceeded': 'Password attempts exceeded',
        'Attempt limit exceeded, please try after some time': 'Attempt limit exceeded, please try after some time',
        'Username/client id combination not found': 'Username/client id combination not found',
        'Password cannot be empty': 'Password cannot be empty',
        'CUSTOM_AUTH is not enabled for the client': 'Password cannot be empty',
        'Custom auth lambda trigger is not configured for the user pool': 'Password cannot be empty',
        'Password does not conform to policy: Password not long enough':
          'Password does not conform to policy: Password not long enough',
        'Password does not conform to policy: Password must have symbol characters':
          'Password does not conform to policy: Password must have symbol characters',
        'Password does not conform to policy: Password must have uppercase characters':
          'Password does not conform to policy: Password must have uppercase characters',
        'Password does not conform to policy: Password must have lowercase characters':
          'Password does not conform to policy: Password must have lowercase characters',
        'Password does not conform to policy: Password must have numeric characters':
          'Password does not conform to policy: Password must have numeric characters',
        'Confirmation code cannot be empty': 'One Time Password cannot be empty',
        'Invalid session for the user, session is expired':
          'Took too long to complete additional login steps. Please log in again.',
        'Code mismatch':
          'The one time password does not match the QR code. Please scan the QR code again and enter a new one time password.',
        'Code mismatch and fail enable Software Token MFA':
          'The one time password does not match the QR code. Please scan the QR code again and enter a new one time password.',
        'Invalid code received for user': 'Incorrect one time password, please try again.',
        'Temporary password has expired and must be reset by an administrator':
          'Temporary password has expired. Please contact support',
      },
    },
    buttons: {
      actions: 'Actions',
      'add-condition': 'Add condition',
      apply: 'Apply',
      cancel: 'Cancel',
      clear: 'Clear',
      clone: 'Clone',
      delete: 'Delete',
      dismiss: 'OK',
      download: 'Download',
      edit: 'Edit',
      execute: 'Execute',
      export: 'Export',
      group: 'Group',
      refresh: 'Refresh',
      remove: 'Remove',
      retry: 'Retry',
      ungroup: 'Ungroup',
    },
    components: {
      'date-range-input': {
        'placeholder-text': 'Select',
        'from-date': 'From: {date}',
        'to-date': 'To: {date}',
      },
    },
    dashboard: {
      language: 'Language',
      languages: {
        en: 'English',
        ja: '日本語',
      },
    },
    errors: {
      'bad-request-implementation-fault': '[{code}]: Bad request. Please contact support',
      'bad-request': '[{code}]: Bad request. Please check information being sent',
      conflict: '[{code}]: A resource already exists with the provided information',
      forbidden: '[{code}]: You lack permissions to perform this action',
      'not-found': '[{code}]: Resource not found',
      'not-implemented': '[{code}]: Not implemented',
      'precondition-failed': '[{code}]: The state on the server has changed. Please refresh and try again',
      'request-timeout': '[{code}]: Client took too long to send request',
      'response-timeout': '[{code}]: Server took too long to respond',
      'server-error': '[{code}]: Server error',
      unauthorized: '[{code}]: Credentials were not provided. Are you logged in?',
      'unexpected-error': 'An unexpected error has occurred. Please contact support',
      unknown: '[{code}]: Unknown',
    },
    sidebar: {
      dashboard: 'Dashboard',
      'file-manager': 'File Manager',
      kym: 'KYM',
    },
    models: {
      accounts: 'Accounts',
      'account-name': 'Account name',
      'account-number': 'Account number',
      'account-subtype': 'Account subtype',
      'account-subtypes': {
        asset_management: 'Asset management',
        brokerage: 'Brokerage',
        brokeragecash: 'Brokerage cash',
        cardloan: 'Card loan',
        checking: 'Checking',
        chochikusavings: 'Checking',
        creditcard: 'Credit card',
        debitcard: 'Debit card',
        defined_contribution_pension: 'Defined contribution pension',
        homeloan: 'Home loan',
        loan_installment: 'Loan installment',
        pension_cash: 'Pension cash',
        pointcard: 'Point card',
        savings: 'Savings',
        storedvalue: 'Stored value',
        tax_payment_reserve_deposit: 'Tax payment reserve deposit',
        term_life: 'Term life',
        termdeposit: 'Term deposit',
        termdeposit_kawase: 'Term deposit kawase',
        termdeposit_manki: 'Term deposit manki',
        termdepositbuilder: 'Term deposit builder',
        termdepositshikumi: 'Term deposit shikumi',
        unknown_aggregation_account_type: 'Unknown',
        whole_life: 'Whole life',
        zaikei: 'Zaikei',
      },
      'account-type': 'Account type',
      'account-types': {
        _: 'Account types',
        bank: 'Bank',
        'cash-wallet': 'Cash wallet',
        'credit-card': 'Credit card',
        insurance: 'Insurance',
        loan: 'Loan',
        manual: 'Manual',
        pension: 'Pension',
        point: 'Point',
        stock: 'Stock',
        'stored-value': 'Stored value',
        unknown: 'Unknown',
      },
      'balance-with-conversion': 'Balance (JPY conversion)',
      balance: 'Balance',
      'branch-code': 'Branch code',
      'branch-name': 'Branch name',
      currency: 'Currency',
      'financial-institution': 'Financial institution',
      'financial-institution-id': 'Financial institution ID',
      'financial-institution-name': 'Financial institution name',
      institutions: 'Institutions',
      'moneytree-id': 'Moneytree ID',
      'net-worth': 'Total user net worth',
      'sum-of-matched': 'Sum of matched account balances',
      'updated-at': 'Last updated',
    },
    none: 'None',
    views: {
      dashboard: {
        messages: {
          'could-not-load-apps': {
            title: "Couldn't load apps",
          },
        },
      },
      'file-manager': {
        accounts: 'accounts',
        'choose-date-range': 'Please choose the date range of transactions to export:',
        'export-accounts': 'Export Accounts',
        'export-failed': 'Export Failed',
        'export-guests': 'Export Guests',
        'export-started': 'Export Started',
        'export-transactions': 'Export Transactions',
        'get-institution-names-failed': 'Could not get institution names',
        guests: 'guests',
        transactions: 'transactions',
        'export-list': {
          'confirm-delete': 'Delete Selected File(s)',
          'confirm-delete-are-you-sure': 'Are you sure you want to delete the {noSelected} selected file(s)?',
          'confirm-delete-cannot-be-recovered': 'Once files are deleted they cannot be recovered.',
          date: 'Date',
          'delete-failure': 'Failed to delete {noAttempted} File(s)',
          'delete-success': 'Deleted {noDeleted} File(s)',
          'download-export-failed': 'Failed to download the export file',
          'export-expiration-info': 'Files are available for {hours} hours after export.',
          'export-list': 'Export List',
          filename: 'Filename (click to dl)',
          filesize: 'Filesize',
          'items-selected': '{noSelected} File(s) Selected',
          'get-export-list-failed': 'Failed to retrieve the export list',
          status: 'Status',
          'status-DONE': 'Export completed',
          'status-expired': 'File expired',
          'status-FAILED': 'Export failed',
          'status-INPROGRESS': 'Exporting',
          'status-QUEUED': 'Export queued',
        },
        'transaction-export': {
          'date-selection-limit-exceeded':
            'Your plan supports access to the last {noOfMonths} months of data.  Please contact sales@moneytree.jp if you would like to upgrade your plan.',
        },
        'unexpected-error': 'An unexpected error has occurred.',
      },
      kym: {
        'by-account-types': 'By Account Type',
        'by-financial-institutions': 'By Institution',
        filters: 'Filters',
        messages: {
          'could-not-load-data': {
            title: "Couldn't load data",
          },
          'not-found': {
            title: "Couldn't load data",
            subtitle: "Reports haven't been generated yet",
          },
        },
        sheets: {
          titles: {
            customers: 'Customers',
            accounts: 'Accounts',
            assets: 'Balances (Assets)',
            liabilities: 'Balances (Liabilities)',
            points: 'Balances (Points)',
          },
        },
        'show-all': 'Show all',
        tables: {
          'account-type': 'Account type',
          accounts: 'Accounts',
          customers: 'Customers',
          balances: 'Balance',
          hidden: 'Hidden',
          institution: 'Institution',
          others: 'Others',
          percentage: '%',
          total: 'Total',
        },
      },
      login: {
        alerts: {
          'csrf-token-expired': 'Form submission took too long.<br>For security reasons please try to log in again',
          'session-expired': 'Session expired.<br>Please login again',
          'user-is-locked': `Your account is locked.<br>Please contact support`,
          'wrong-credentials': 'Incorrect username and/or password.<br>Please check them and try again',
        },
        email: 'Email',
        login: 'Login',
        password: 'Password',
        username: 'Username',
      },
    },
    events: {
      copyToClipboardSuccess: 'Copied to clipboard',
      copyToClipboardFailure: 'Unable to copy to clipboard',
    },
  },
};
