import * as z from 'zod';

export type SdkConfig = z.infer<typeof SdkConfig>;

export const SdkConfig = z.object({
  dsn: z.string(),
  environment: z.string(), // union?
  release: z.string().optional(),
  tracesSampleRate: z.number().default(1.0),
});

export type ScopeConfig = z.infer<typeof ScopeConfig>;

export const ScopeConfig = z.object({
  tags: z.record(z.string().or(z.number())).optional(),
});
