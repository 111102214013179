import * as z from 'zod';
import { ScopeConfig } from '@/services/sentry/types';

export const fromEnv = (): z.SafeParseReturnType<z.input<typeof ScopeConfig>, ScopeConfig> => {
  return ScopeConfig.safeParse({
    tags: (() => {
      try {
        return process.env.VUE_APP_SENTRY_TAGS ? JSON.parse(process.env.VUE_APP_SENTRY_TAGS) : undefined;
      } catch (_) {
        // Hacky but forces the safeParse to fail on malformed json string
        return Symbol('');
      }
    })(),
  });
};
