import { provide, inject, InjectionKey } from 'vue';
import { Logger } from '@/services/logger';

const injectionKey = Symbol('logger') as InjectionKey<Logger>;

export function provideLogger(logger: Logger): void {
  return provide(injectionKey, logger);
}

/**
 * Composition function that returns Log<LogEntry<string | Error>>
 */
function useLogger(): Logger {
  return inject(injectionKey, () => null);
}

export default useLogger;
