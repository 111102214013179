import VueI18n from 'vue-i18n';
import { toTranslateFn, TranslateFn } from '@/i18n';
import useI18n from '@/hooks/useI18n';
import useLocale from '@/hooks/useLocale';

function useTranslate(): TranslateFn {
  const i18n = useI18n();
  const locale = useLocale();

  return (key: VueI18n.Path, locale_?: VueI18n.Locale | VueI18n.Values, value?: VueI18n.Values) => {
    if (typeof locale_ === 'string') {
      return toTranslateFn(i18n)(key, locale_, value);
    } else {
      return toTranslateFn(i18n)(key, locale.value, locale_);
    }
  };
}

export default useTranslate;
