/**
 * This file exports a persistedRef() function, which returns a Ref, just like Vue's ref() function from
 * the composition API does. The difference is that the value of a ref created through persistedRef is
 * always persisted in window.localStorage.
 */

import { ZodType } from 'zod';
import { ref, Ref, watch } from 'vue';

interface PersistedRefOptions<VALUE> {
  key: string;
  shape: ZodType<VALUE>;
}

interface PersistedRefWithFallbackOptions<VALUE> extends PersistedRefOptions<VALUE> {
  default: VALUE;
}

const storage = window.localStorage;

function _getValue<VALUE>(options: PersistedRefOptions<VALUE>): VALUE | null {
  const serializedValue = storage.getItem(options.key);
  if (serializedValue === null) return null;

  let parsedValue;
  try {
    parsedValue = JSON.parse(serializedValue);
  } catch (e) {
    console.warn(`Couldn't parse stored value for key: '${options.key}'`, e);
    storage.removeItem(options.key);
    return null;
  }

  const typeCheckResult = options.shape.safeParse(parsedValue);
  if (!typeCheckResult.success) {
    console.warn(
      `The stored value for key '${options.key}' doesn't adhere to the expected schema`,
      typeCheckResult.error.toString()
    );
    storage.removeItem(options.key);
    return null;
  }
  return typeCheckResult.data;
}

function _setValue<VALUE>(key: string, value: VALUE): void {
  if (value === undefined) {
    storage.removeItem(key);
  } else {
    storage.setItem(key, JSON.stringify(value));
  }
}

export function persistedRef<VALUE>(
  options: PersistedRefWithFallbackOptions<NonNullable<VALUE>>
): Ref<NonNullable<VALUE>>;
export function persistedRef<VALUE>(options: PersistedRefOptions<VALUE>): Ref<VALUE | null>;
export function persistedRef<VALUE>(
  options: PersistedRefWithFallbackOptions<NonNullable<VALUE>> | PersistedRefOptions<VALUE>
): Ref<VALUE | null> | Ref<NonNullable<VALUE>> {
  // Attempt to retrieve the value from localStorage
  let startingValue = _getValue(options);

  // Value was not found in localStorage, so attempt to use the default value if there is one
  if (startingValue === null && 'default' in options) {
    startingValue = options.default;
  }

  // create a Ref, so we can watch for changes and persist them
  const persisted$ = ref<VALUE | null>(startingValue);

  watch(persisted$, (value) => {
    _setValue(options.key, value);
  });

  return persisted$ as Ref<VALUE>;
}
