import * as types from './types';

export { types };

export const contramap =
  <A, B>(fn: (x: A) => B) =>
  (logger: types.Logger<B>): types.Logger<A> => {
    return (x) => logger(fn(x));
  };

export const merge = <A>(loggers: ReadonlyArray<types.Logger<A>>): types.Logger<A> => {
  return (x) => loggers.forEach((fn) => fn(x));
};

export const filter = <A, B extends A>(pred: (x: A) => x is B, logger: types.Logger<B>): types.Logger<A> => {
  return (x) => (pred(x) ? logger(x) : null);
};
