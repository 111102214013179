
import { computed, defineComponent, ref, watch } from 'vue';
import { VSnackbar } from 'vuetify/lib';
import { v4 as uuid } from 'uuid';
import { MtButton } from '@/components/MtButton';
import { Message } from '@/components/MtMessages/Message';
import { DEFAULT_MESSAGE_TIMEOUT_MS } from './MtMessages';
import MtTimer from '@/components/MtTimer/MtTimer.vue';
import { MtFixedContent } from '@/components/MtFixedContent';
import useMessageService from '@/hooks/useMessageService';

export default defineComponent({
  components: {
    MtTimer,
    MtButton,
    MtFixedContent,
    VSnackbar,
  },
  setup() {
    const messageService = useMessageService();
    const messageQueue$ = ref<Array<Message>>([]);
    const activeMessage$ = ref<Message | null>(null);
    const messageId$ = ref<string | null>(null);
    const timeout$ = ref<number | null>(null);
    const disabled$ = ref<boolean>(false);

    const timeoutId$ = ref<number | null>(null);

    const snackbarModel$ = ref<boolean>(false);

    const lastClearSignal = ref<number>(messageService.clearSignal$.value);
    const lastShowMessageSignal = ref<number>(messageService.showMessageSignal$.value);

    watch(messageQueue$, (messages) => {
      if (!messages.length) return;

      if (activeMessage$.value === null) loadNextMessage();
    });

    watch(messageService.clearSignal$, (signal) => {
      // watch should only trigger when the signal changes. So lastClearSignal is not really needed, but just 100% being safe.
      if (signal !== lastClearSignal.value) {
        activeMessage$.value = null;
        messageQueue$.value = [];
        snackbarModel$.value = false;

        cleanup();
        lastClearSignal.value = signal;
      }
    });

    watch(messageService.showMessageSignal$, (signal) => {
      // watch should only trigger when the signal changes. So lastShowMessageSignal is not really needed, but just 100% being safe.
      if (signal !== lastShowMessageSignal.value) {
        cleanup();
        loadNextMessage();
        lastShowMessageSignal.value = signal;
      }
    });

    watch(snackbarModel$, (show) => {
      if (!show) {
        setTimeout(() => {
          loadNextMessage();
        }, 500);
      }
    });

    function consumeMessages(): void {
      if (!messageService.messageQueue$.value) return;

      messageQueue$.value = [...messageQueue$.value, ...messageService.messageQueue$.value];

      // Marked the queue as "consumed"
      messageService.messageQueue$.value = null;
    }
    watch(messageService.messageQueue$, consumeMessages);
    // In case there are already messages pilled up
    consumeMessages();

    function showSnackbar(): void {
      if (!activeMessage$.value) return;
      snackbarModel$.value = true;
    }

    function hideSnackbar(): void {
      if (!activeMessage$.value) return;
      snackbarModel$.value = false;
    }

    function cleanup(): void {
      if (timeoutId$.value !== null) {
        window.clearTimeout(timeoutId$.value);
        timeoutId$.value = null;
      }
    }

    function triggerAction(): void {
      disabled$.value = true;
      cleanup();
      hideSnackbar();

      if (action$.value?.onClick && activeMessage$.value !== null) {
        action$.value.onClick(activeMessage$.value);
      }
    }

    function loadNextMessage(): void {
      disabled$.value = false;

      if (!messageQueue$.value.length) {
        activeMessage$.value = null;
        return;
      }

      messageId$.value = uuid();

      const nextMessage: Message = messageQueue$.value.shift() as Message;
      if (nextMessage.timeout === null) {
        timeout$.value = null;
      } else {
        timeout$.value = nextMessage.timeout ?? DEFAULT_MESSAGE_TIMEOUT_MS;

        if (!nextMessage.action?.autoTrigger) {
          timeoutId$.value = window.setTimeout(() => {
            hideSnackbar();
          }, timeout$.value);
        }
      }

      activeMessage$.value = nextMessage;
      showSnackbar();
    }

    const action$ = computed<Message['action'] | undefined>(() => {
      if (!activeMessage$.value) return;

      return {
        text: 'mt.buttons.dismiss',
        ...(activeMessage$.value.action ? activeMessage$.value.action : {}),
      } as Message['action'];
    });

    return {
      action$,
      activeMessage$,
      disabled$,
      messageId$,
      snackbarModel$,
      timeout$,

      triggerAction,
    };
  },
});
