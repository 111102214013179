
import { defineComponent } from 'vue';
import { MtButton } from '@/components/MtButton';

export default defineComponent({
  components: { MtButton },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
