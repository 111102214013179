/*
 * TODO: Move to a more useful location
 *   2020-12-16 by: @maragon
 */
import VueI18n from 'vue-i18n';
import { number, object } from 'zod';

const NetworkError = object({
  status: number(),
}).nonstrict();

interface NetworkError {
  status: number;
}

function isNetworkError(e: unknown): e is NetworkError {
  return NetworkError.safeParse(e).success;
}

/**
 * Translate a {@link NetworkError} error into a user-friendly message
 * @param e
 */
export function translateNetworkError(e: NetworkError): string {
  switch (e.status) {
    case 400: // Bad Request
      return 'mt.errors.bad-request';
    case 401: // Unauthorized
      // FIXME: Trickle down this error in a more elegant way

      // return 'mt.errors.unauthorized';

      throw e;
    case 403: // Forbidden
      return 'mt.errors.forbidden';
    case 404: // Not Found
    case 410: // Gone
      return 'mt.errors.not-found';
    case 405: // Method Not Allowed
    case 406: // Not Acceptable
    case 411: // Length Required
    case 413: // Payload Too Large
    case 414: // URI Too Long
    case 415: // Unsupported Media Type
    case 431: // Request Header Fields Too Large
      return 'mt.errors.bad-request-implementation-fault';
    case 408: // Request Timeout
      return 'mt.errors.request-timeout';
    case 409: // Conflict
      return 'mt.errors.conflict';
    case 412: // Precondition Failed
      return 'mt.errors.precondition-failed';
    case 429: // Too Many Redirects
    case 500: // Internal Server Error
    case 502: // Bad Gateway
    case 503: // Server Unavailable
    case 508: // Loop Detected
      return 'mt.errors.server-error';
    case 501: // Not implemented
      return 'mt.errors.not-implemented';
    case 504: // Gateway timeout
      return 'mt.errors.response-timeout';
    default:
      return 'mt.errors.unknown';
  }
}

/**
 * Translates an error into a user-friendly message
 */
export function translateError(e: unknown): string | [string, VueI18n.Values] {
  if (typeof e === 'object' && e !== null) {
    if (e instanceof Response) {
      return [translateNetworkError(e), { code: e.status }];
    } else if (isNetworkError(e)) {
      return translateNetworkError(e);
    } else if (e instanceof Error) {
      return e.message;
    }
  } else if (typeof e === 'string') {
    return e;
  }

  return 'Unknown';
}
