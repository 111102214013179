
import { defineComponent, onMounted, onUnmounted, ref, toRefs, watch } from 'vue';
import { MtTimerEvents, MtTimerProps } from './MtTimer';
import { useEmitter } from '@/utils/useEmitter';

export default defineComponent({
  props: {
    color: {
      type: String,
      required: false,
    },
    refreshInterval: {
      type: Number,
      default: 50,
    },
    time: {
      type: Number,
    },
  },
  setup(props: MtTimerProps) {
    const { refreshInterval: refreshInterval$, time: time$ } = toRefs(props);

    const emitter = useEmitter<MtTimerEvents>();

    const start$ = ref<number | undefined>();
    const progress$ = ref<number | undefined>();
    const updateInterval$ = ref<number | undefined>();
    const timeout$ = ref<number | undefined>();

    function updateProgress() {
      if (!start$.value) {
        return;
      }
      const timePassed = Date.now() - start$.value;
      const newValue = (timePassed / time$.value) * 100;

      if (progress$.value !== newValue) {
        progress$.value = newValue;
      }
    }

    function finish() {
      progress$.value = 100;

      cleanup();
      emitter('finish');
    }

    function registerTimers() {
      progress$.value = 0;
      start$.value = Date.now();
      updateInterval$.value = window.setInterval(updateProgress, refreshInterval$.value);
      timeout$.value = window.setTimeout(finish, time$.value);
    }

    function cleanup() {
      if (updateInterval$.value !== undefined) {
        window.clearInterval(updateInterval$.value);
        updateInterval$.value = undefined;
      }
      if (timeout$.value !== undefined) {
        window.clearTimeout(timeout$.value);
        timeout$.value = undefined;
      }
    }

    function reset() {
      cleanup();
      registerTimers();
    }

    onMounted(registerTimers);
    onUnmounted(cleanup);

    watch(refreshInterval$, reset);
    watch(time$, reset);

    return {
      progress$,
    };
  },
});
