import { provide, inject, InjectionKey, ref, Ref } from 'vue';
import { Locale } from '@/models/Locale';
import useAppConfig from '@/hooks/useAppConfig';

const injectionKey = Symbol('locale') as InjectionKey<Ref<Locale>>;

export function provideLocale(locale: Ref<Locale>): void {
  return provide(injectionKey, locale);
}

/**
 * Composition function that returns Config
 */
function useLocale(): Ref<Locale> {
  const appConfig = useAppConfig();
  return inject(injectionKey, ref(appConfig.defaultLocale));
}

export default useLocale;
