
import { defineComponent, ref, watch } from 'vue';
import useLocale from '@/hooks/useLocale';

export default defineComponent({
  setup() {
    const locale$ = useLocale();

    const languageOptions = [
      { label: 'mt.dashboard.languages.ja', value: 'ja' as const },
      { label: 'mt.dashboard.languages.en', value: 'en' as const },
    ];
    const languageMenuModel$ = ref<number>(languageOptions.findIndex((option) => locale$.value === option.value));

    watch(languageMenuModel$, (value) => {
      if (value < 0 || value >= languageOptions.length) throw new Error(`Unexpected language option index '${value}'`);

      const newLocale = languageOptions[value].value;
      if (locale$.value === newLocale) return;

      locale$.value = languageOptions[value].value;
    });
    watch(locale$, (value) => {
      const languageIndex = languageOptions.findIndex((option) => option.value === value);
      if (languageIndex === -1) throw new Error(`Could not find index for language option '${value}'`);

      if (languageMenuModel$.value === languageIndex) return;

      languageMenuModel$.value = languageIndex;
    });

    return {
      languageOptions,

      languageMenuModel$,
    };
  },
});
