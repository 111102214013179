import { provide, inject, ref, Ref, InjectionKey } from 'vue';

export const injectionKey = Symbol('layoutRect') as InjectionKey<Ref<DOMRectReadOnly | null>>;

export function provideLayoutRect(rect: Ref<DOMRectReadOnly | null>): void {
  provide(injectionKey, rect);
}

/**
 * Composition function that returns provided DOMRect in the current context
 */
function useLayoutRect(): Ref<DOMRectReadOnly | null> {
  return inject(injectionKey, ref(null));
}

export default useLayoutRect;
