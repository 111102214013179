var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "mt-button",
    {
      staticClass: "mt-delete-button",
      attrs: { elevation: "1", disabled: _vm.loading, color: "error" },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _vm.loading
        ? _c("v-progress-circular", {
            attrs: { size: 16, width: 3, indeterminate: "" },
          })
        : _vm._e(),
      _c("v-icon", { attrs: { small: "" } }, [_vm._v("fas fa-trash")]),
      _vm._v(" " + _vm._s(_vm.$t("mt.buttons.delete")) + " "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }