var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "mt-fixed-content",
    { staticClass: "mt-messages" },
    [
      _c("v-snackbar", {
        staticClass: "mt-message",
        attrs: {
          transition: "slide-y-transition",
          color: _vm.activeMessage$ && _vm.activeMessage$.type,
          timeout: "-1",
          rounded: "",
          text: "",
          top: "",
        },
        scopedSlots: _vm._u(
          [
            _vm.activeMessage$
              ? {
                  key: "default",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "mt-message__content" }, [
                        _c("div", { staticClass: "mt-message__text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                typeof _vm.activeMessage$.text === "string"
                                  ? _vm.$t(_vm.activeMessage$.text)
                                  : _vm.$t(
                                      _vm.activeMessage$.text[0],
                                      _vm.activeMessage$.text[1]
                                    )
                              ) +
                              " "
                          ),
                        ]),
                        _vm.activeMessage$.secondaryText
                          ? _c(
                              "div",
                              { staticClass: "mt-message__secondary-text" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      typeof _vm.activeMessage$
                                        .secondaryText === "string"
                                        ? _vm.$t(
                                            _vm.activeMessage$.secondaryText
                                          )
                                        : _vm.$t(
                                            _vm.activeMessage$.secondaryText[0],
                                            _vm.activeMessage$.secondaryText[1]
                                          )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  },
                  proxy: true,
                }
              : null,
            _vm.activeMessage$ && _vm.action$
              ? {
                  key: "action",
                  fn: function ({ attrs }) {
                    return [
                      _vm.action$.autoTrigger && _vm.timeout$ !== null
                        ? _c("mt-timer", {
                            attrs: { time: _vm.timeout$, size: "20" },
                            on: { finish: _vm.triggerAction },
                          })
                        : _vm._e(),
                      _c(
                        "mt-button",
                        _vm._b(
                          {
                            attrs: {
                              text: "",
                              color: _vm.activeMessage$.type,
                              disabled: _vm.disabled$,
                            },
                            on: { click: _vm.triggerAction },
                          },
                          "mt-button",
                          attrs,
                          false
                        ),
                        [_vm._v(" " + _vm._s(_vm.$t(_vm.action$.text)) + " ")]
                      ),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
        model: {
          value: _vm.snackbarModel$,
          callback: function ($$v) {
            _vm.snackbarModel$ = $$v
          },
          expression: "snackbarModel$",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }