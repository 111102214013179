var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "mt-button",
    {
      staticClass: "mt-cancel-button",
      attrs: { elevation: "1", disabled: _vm.disabled },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [_vm._v(" " + _vm._s(_vm.$t("mt.buttons.cancel")) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }