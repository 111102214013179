import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import * as logEntry from '@/utils/logEntry';
import * as logger from '@/utils/logger';
import * as types from './types';

export { types };

export const setupSdk = (config: types.SdkConfig): void => {
  Sentry.init({
    Vue,
    dsn: config.dsn,
    environment: config.environment,
    release: config.release,
    // Ignore "Failed to fetch" because they are more likely to be false alarms caused by redirects than real errors.
    ignoreErrors: ['TypeError: Failed to fetch'],
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', /^\//],
      }),
    ],
    tracesSampleRate: config.tracesSampleRate,
  });
};

export const setupScope = (config: types.ScopeConfig): void => {
  Sentry.configureScope((scope) => {
    if (config.tags) {
      scope.setTags(config.tags);
    }
  });
};

export const toLogger = (): logger.types.Logger<logEntry.types.LogEntry<string | Error>> => {
  return logger.merge([
    logger.filter((x): x is logEntry.types.LogEntry<string> => typeof x.message === 'string', toMessageLogger()),
    logger.filter((x): x is logEntry.types.LogEntry<Error> => x.message instanceof Error, toExceptionLogger()),
  ]);
};

export const toMessageLogger = (): logger.types.Logger<logEntry.types.LogEntry<string>> => {
  return (log) => {
    Sentry.captureMessage(log.message, {
      level: log.level,
      tags: log.tags,
      extra: log.context,
    });
  };
};

export const toExceptionLogger = (): logger.types.Logger<logEntry.types.LogEntry<Error>> => {
  return (log) => {
    Sentry.captureException(log.message, {
      level: log.level,
      tags: log.tags,
      extra: log.context,
    });
  };
};
