import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueI18n from 'vue-i18n';
import en from 'vuetify/src/locale/en';
import ja from 'vuetify/src/locale/ja';

export type Config = {
  i18n: VueI18n;
};

export function configureVuetify({ i18n }: Config): Vuetify {
  Vue.use(Vuetify);

  i18n.mergeLocaleMessage('en', {
    $vuetify: en,
  });
  i18n.mergeLocaleMessage('ja', {
    $vuetify: ja,
  });

  const vuetify = new Vuetify({
    icons: {
      // Use Font Awesome icons as default
      iconfont: 'fa',
    },
    lang: {
      t: (key, ...params) => {
        const result = i18n.t(key, params);
        if (typeof result !== 'string') throw new Error(`Translation for Vuetify's i18n key '${key}' is missing`);

        return result;
      },
    },
    theme: {
      options: {
        // Enabling customProperties allows using the theme values via `var()` in scss
        customProperties: true,
      },
      themes: {
        light: {
          primary: '#16D936',
          link: '#1867c0',
        },
      },
    },
  });

  return vuetify;
}
