import * as types from './types';

class MissingTranslationError extends Error {
  constructor(params: types.Parameters) {
    super(`Missing translation for: ${JSON.stringify(params)}`);
    this.name = 'MissingTranslationError';
  }
}

export default MissingTranslationError;
