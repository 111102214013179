
import { defineComponent } from 'vue';
import useLayoutRect from '@/hooks/useLayoutRect';
import useDomRectOffsetStyle from '@/hooks/useDomRectOffsetStyle';

export default defineComponent({
  setup() {
    const layoutRect$ = useLayoutRect();
    const layoutOffsetStyle$ = useDomRectOffsetStyle(layoutRect$);

    return {
      layoutOffsetStyle$,
    };
  },
});
