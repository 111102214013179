import * as z from 'zod';
import { SdkConfig } from '@/services/sentry/types';

export const fromEnv = (): z.SafeParseReturnType<z.input<typeof SdkConfig>, SdkConfig> => {
  return SdkConfig.safeParse({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    release: process.env.VUE_APP_SENTRY_RELEASE,
    tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE || ''),
  });
};
