import { ref, Ref } from 'vue';
import { Message } from '@/components/MtMessages/Message';

export interface MessageService {
  readonly messageQueue$: Ref<Array<Message> | null>;
  readonly clearSignal$: Ref<number>;
  readonly showMessageSignal$: Ref<number>;

  showMessage(message: Message): void;

  queueMessage(message: Message): void;

  clearMessages(): void;
}

export class SimpleMessageService implements MessageService {
  readonly messageQueue$ = ref<Array<Message> | null>(null);
  // Timestamps signifying times of events. Watchers can watch them to see if the timestamps change.
  readonly clearSignal$ = ref<number>(0);
  readonly showMessageSignal$ = ref<number>(0);

  clearMessages(): void {
    this.clearSignal$.value = Date.now();
  }

  // Clears other messages from the queue and immediately shows the given message.
  showMessage(message: Message): void {
    this.messageQueue$.value = [message];
    this.showMessageSignal$.value = Date.now();
  }

  queueMessage(message: Message): void {
    if (this.messageQueue$.value === null) {
      this.messageQueue$.value = [message];
    } else {
      this.messageQueue$.value.push(message);
    }
  }
}
