import { PromiseRejectionEventHandler } from '@/services/errors/PromiseRejectionEventHandler';
import { MessageService } from '@/components/MtMessages/MessageService';
import { translateError } from '@/utils/error';

export type Config = {
  messageService: MessageService;
};

export class UnexpectedErrorHandler implements PromiseRejectionEventHandler<unknown> {
  protected readonly _messageService: MessageService;

  constructor(config: Config) {
    this._messageService = config.messageService;
  }

  canHandle(event: PromiseRejectionEvent, reason: unknown): reason is unknown {
    return true;
  }

  handle(event: PromiseRejectionEvent, reason: unknown): void {
    this._messageService.queueMessage({
      type: 'error',
      text: 'mt.errors.unexpected-error',
      secondaryText: translateError(reason),
    });

    console.error('Encountered unexpected error related to event: %o', event, reason);
  }
}
