var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-progress-circular",
    _vm._b(
      { staticClass: "mt-timer__progress", attrs: { value: _vm.progress$ } },
      "v-progress-circular",
      this.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }