import * as app from '@/configs/app';

export type Config = {
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
};

export const fromAppConfig = (config: app.Config): Config => {
  return {
    aws_cognito_region: config.cognitoAwsRegion,
    aws_user_pools_id: config.cognitoUserPoolId,
    aws_user_pools_web_client_id: config.cognitoAppClientId,
  };
};
