import Vue from 'vue';
import { CombinedVueInstance } from 'vue/types/vue';
import { Props } from '@/types/vue';

export const DEFAULT_MESSAGE_TIMEOUT_MS = 7000;

export interface MtMessagesData {}

export interface MtMessagesMethods {}

export interface MtMessagesComputed {}

export interface MtMessagesProps extends Props {}

export interface MtMessagesEvents {}

export type MtMessagesInstance = CombinedVueInstance<
  Vue,
  MtMessagesData,
  MtMessagesMethods,
  MtMessagesComputed,
  MtMessagesProps,
  void
>;
