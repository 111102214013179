import { LocaleMessageObject } from 'vue-i18n';

export const ja: LocaleMessageObject = {
  mt: {
    login: {
      'back-to-sign-in': 'ログインに戻る',
      'signed-in': {
        'signed-in': 'ログインしました',
        'go-to-dashboard': 'ダッシュボードへ',
      },
      'sign-in': {
        'sign-in-to-your-account': 'アカウントにログイン',
        username: 'ユーザー名',
        password: 'パスワード',
        'sign-in': 'ログイン',
        'storage-unavailable-error':
          'エラー:お使いのブラウザは、予期せぬエラーが発生しました。ブラウザを再開し、再度お試しください。',
        'storage-unavailable-subtext':
          '問題が解決しない場合は、サポートまでご連絡ください。その際、エラーメッセージもお知らせください。',
      },
      'required-change-password': {
        'password-requirements': 'パスワードの条件',
        'minimum-characters': '8文字以上',
        'contains-number': '少なくとも1つの数字が含まれる',
        'contains-special-character': '少なくとも1つの特殊文字が含まれている',
        'valid-special-characters': '（^$*.[]{}()?-"!@#%&/,><\':;|_~`+=)',
        'contains-lowercase-character': '小文字が1文字以上含まれる',
        'contains-uppercase-character': '少なくとも1つの大文字が含まれる',
        'change-password': 'パスワードを変更',
        'new-password': '新しいパスワード',
        'confirm-new-password': '新しいパスワードを確認',
        'passwords-do-not-match': 'パスワードが一致しません',
        change: '変更',
      },
      'totp-input': {
        'confirm-totp-code': 'ワンタイムパスワードを確認',
        'verification-code': 'ワンタイムパスワード',
        confirm: '確定',
      },
      'totp-setup': {
        title: 'ワンタイムパスワードの設定',
        'subtitle-register-secret-code': {
          body: '認証アプリで以下の{0}、または{1}してください',
          qrCode: 'QRコードをスキャン',
          secretCode: 'シークレットキーを入力',
        },
        'subtitle-enter-otp': '認証アプリに表示されたワンタイムパスワードを入力してください',
        'step-register-secret-code': '認証アプリに登録',
        'step-enter-otp': 'ワンタイムパスワードの入力',
        'field-secret-code': 'シークレットキー',
        'enter-totp-code': 'ワンタイムパスワードを入力',
        verify: '認可',
        'qr-code': 'QRコード',
        'totp-code-cannot-be-empty': 'ワンタイムパスワードは必須です',
        'totp-code-too-short': 'ワンタイムパスワードは6文字以上を入力してください',
        'action-back-to-signin': 'ログインに戻る',
        'action-previous-step': '戻る',
        'action-next-step': '次へ',
        'action-complete-setup': '設定を完了',
      },
      errors: {
        'unexpected-error': '予期しないエラー状態が発生しました｡ お手数ですがサポートセンターに連絡してください',
      },
      exceptions: {
        'User does not exist': 'ユーザーが存在しません',
        'Incorrect username or password': 'ユーザー名またはパスワードが違います',
        'User is not confirmed': 'ユーザーは検証されていません',
        'User already exists': 'ユーザーは既に存在します',
        'Invalid verification code provided, please try again':
          '指定された確認コードが無効です。もう一度お試しください',
        'Invalid password format': 'パスワードのフォーマットが不正です',
        'Account recovery requires verified contact information': 'アカウントの復元には確認済みの連絡先情報が必要です',
        'Invalid phone number format':
          '不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900',
        'An account with the given email already exists': 'そのメールアドレスは既に存在します',
        'Username cannot be empty': 'ユーザー名は必須です',
        'Password attempts exceeded': 'パスワード試行回数が超過しました',
        'Attempt limit exceeded, please try after some time':
          '試行制限を超過しました。しばらくしてからもう一度お試しください',
        'Username/client id combination not found': 'ユーザーが存在しません',
        'Password cannot be empty': 'パスワードは必須です',
        'CUSTOM_AUTH is not enabled for the client': 'パスワードは必須です',
        'Custom auth lambda trigger is not configured for the user pool': 'パスワードは必須です',
        'Password does not conform to policy: Password not long enough': 'パスワードは8文字以上を入力してください',
        'Password does not conform to policy: Password must have symbol characters':
          'パスワードには特殊文字を含めてください',
        'Password does not conform to policy: Password must have uppercase characters':
          'パスワードには大文字を含めてください',
        'Password does not conform to policy: Password must have lowercase characters':
          'パスワードには小文字を含めてください',
        'Password does not conform to policy: Password must have numeric characters':
          'パスワードには数字を含めてください',
        'Confirmation code cannot be empty': 'ワンタイムパスワードは必須です',
        'Invalid session for the user, session is expired': 'セッションが切れました。もう一度ログインしてください',
        'Code mismatch':
          'ワンタイムパスワードがQRコードと一致しません。もう一度QRコードを読み取って、新しいワンタイムパスワードを入力してください',
        'Code mismatch and fail enable Software Token MFA':
          'ワンタイムパスワードがQRコードと一致しません。もう一度QRコードを読み取って、新しいワンタイムパスワードを入力してください',
        'Invalid code received for user': 'ワンタイムパスワードが正しくありません、もう一度入力してください',
        'Temporary password has expired and must be reset by an administrator':
          '一時パスワードの有効期限が切れました。お手数ですがサポートにご連絡ください',
      },
    },
    buttons: {
      actions: 'アクション',
      'add-condition': '新規追加',
      apply: '実行',
      cancel: 'キャンセル',
      clear: '取り消す',
      clone: 'コビー',
      delete: '削除する',
      dismiss: 'OK',
      download: 'ダウンロード',
      edit: '編集',
      execute: '実行',
      export: '出力する',
      group: 'グループ化',
      refresh: '更新',
      remove: '消去',
      retry: 'リトライ',
      ungroup: 'グループ解除',
    },
    components: {
      'date-range-input': {
        'placeholder-text': '日付範囲を選択',
        'from-date': '開始日: {date}',
        'to-date': '終了日: {date}',
      },
    },
    dashboard: {
      language: '言語',
      languages: {
        en: 'English',
        ja: '日本語',
      },
    },
    errors: {
      'bad-request-implementation-fault': '「{code}」不正リクエストです。お手数ですがサポートにご確認ください',
      'bad-request': '「{code}」不正リクエストです。エラーメッセージを確認してください',
      conflict: '「{code}」提供されたソースは既に存在しています',
      forbidden: '「{code}」認証情報が不足しているため実行できません',
      'not-found': '「{code}」リソースが見つかりません',
      'not-implemented': '「{code}」未実装エラー',
      'precondition-failed': '「{code}」サーバーに変更があります。もう一度更新してから実行してください',
      'request-timeout': '「{code}」クライアントの接続タイムアウトです',
      'response-timeout': '「{code}」サーバーの接続タイムアウトです',
      'server-error': '「{code}」サーバーエラーです',
      unauthorized: '「{code}」認証エラーが発生しました。ログイン内容（ID・パスワード）を確認してください',
      'unexpected-error': '予期しないエラー状態が発生しました｡ お手数ですがサポートに連絡してください',
      unknown: '「{code}」不明のエラー',
    },
    sidebar: {
      dashboard: 'ダッシュボード',
      'file-manager': 'データ出力',
      kym: 'データスナップショット',
    },
    models: {
      accounts: '口座数',
      'account-name': '口座名',
      'account-number': '口座番号',
      'account-subtype': 'アカウント サブタイプ',
      'account-subtypes': {
        asset_management: '投資信託',
        brokerage: '有価証券',
        brokeragecash: '預り金',
        cardloan: 'カードローン',
        checking: '当座預金',
        chochikusavings: '貯蓄預金',
        creditcard: 'クレジットカード',
        debitcard: 'デビットカード',
        defined_contribution_pension: '確定拠出年金',
        homeloan: '住宅ローン',
        loan_installment: 'ローン',
        pension_cash: '確定拠出年金',
        pointcard: 'ポイントカード',
        savings: '普通預金',
        storedvalue: '電子マネー',
        tax_payment_reserve_deposit: '納税準備預金',
        term_life: '保険',
        termdeposit: '定期預金',
        termdeposit_kawase: '為替預金',
        termdeposit_manki: '満期預金',
        termdepositbuilder: '積立預金',
        termdepositshikumi: '仕組預金',
        unknown_aggregation_account_type: '不明',
        whole_life: '生命保険',
        zaikei: '財形貯蓄',
      },
      'account-type': 'アカウントタイプ',
      'account-types': {
        _: 'アカウントタイプ',
        bank: '銀行',
        'cash-wallet': '現金',
        'credit-card': 'クレジットカード',
        insurance: '保険',
        loan: 'ローン',
        manual: 'マニュアル口座',
        pension: '確定拠出年金',
        point: 'ポイント',
        stock: '投資',
        'stored-value': 'プリペイド',
        unknown: '不明',
      },
      'balance-with-conversion': '残高（日本円に換算）',
      balance: '残高',
      'branch-code': '支店番号',
      'branch-name': '支店名',
      currency: '通貨',
      'financial-institution': '金融機関',
      'financial-institution-id': '銀行・ID',
      'financial-institution-name': '銀行名',
      institutions: '金融機関',
      'moneytree-id': 'Moneytree ID',
      'net-worth': 'ユーザー資産合計',
      'sum-of-matched': '該当する口座全ての残高合計',
      'updated-at': '最終データ更新日',
    },
    none: '未選択',
    views: {
      dashboard: {
        messages: {
          'could-not-load-apps': {
            title: 'アプリケーションを読み込むことができません',
          },
        },
      },
      'file-manager': {
        accounts: '口座',
        'choose-date-range': '日付範囲を選択してください',
        'export-accounts': '口座を出力',
        'export-failed': '出力に失敗',
        'export-guests': '顧客一覧を出力',
        'export-started': '出力を処理中',
        'export-transactions': '取引明細を出力',
        'get-institution-names-failed': '金融機関名が読み込めません',
        guests: '顧客一覧',
        transactions: '取引明細',
        'export-list': {
          'confirm-delete': '選択されたファイルの削除',
          'confirm-delete-are-you-sure': '選択された{noSelected}個のファイルを削除してもよろしいですか？',
          'confirm-delete-cannot-be-recovered': '一度削除されたファイルを復元することはできませんのでご注意ください。',
          date: '日付',
          'delete-failure': '{noAttempted}個のファイルの削除に失敗しました。',
          'delete-success': '{noDeleted}個のファイルを削除しました。',
          'download-export-failed': '出力したデータのダウンロードが完了しませんでした',
          'export-expiration-info': 'ファイルアクセス期間は出力後{hours}時間以内となります。',
          'export-list': '出力データの一覧',
          filename: 'ファイル名（ダウンロード）',
          filesize: 'ファイルサイズ',
          'get-export-list-failed': '出力したデータの一覧が表示できません',
          'items-selected': '{noSelected}個のファイルが選択されています。',
          status: 'ステータス',
          'status-DONE': '出力可',
          'status-expired': 'ファイル有効期限切れ',
          'status-FAILED': '出力失敗',
          'status-INPROGRESS': '出力中',
          'status-QUEUED': '出力待機中',
        },
        'transaction-export': {
          'date-selection-limit-exceeded':
            '現在ご利用のプランでは過去{noOfMonths}ヶ月間の出力が可能です。この期間以前のデータ出力をご希望される場合はsales@moneytree.jp にご連絡ください。',
        },
        'unexpected-error': 'エラーが発生しました',
      },
      kym: {
        'by-account-types': '口座種別',
        'by-financial-institutions': '金融機関別',
        filters: 'フィルター',
        messages: {
          'could-not-load-data': {
            title: 'データを読み込むことができません',
          },
          'not-found': {
            title: 'データを読み込むことができません',
            subtitle: '既作成したレポートがありません',
          },
        },
        sheets: {
          titles: {
            customers: '顧客数',
            accounts: '口座数',
            assets: '残高（資産）',
            liabilities: '残高（負債）',
            points: '残高（ポイント）',
          },
        },
        'show-all': '全てを表示',
        tables: {
          'account-type': '口座種別',
          accounts: '口座数',
          customers: '顧客数',
          balances: '残高',
          hidden: '非表示',
          institution: '金融機関',
          others: 'その他',
          percentage: '割合%',
          total: '合計',
        },
      },
      login: {
        alerts: {
          'csrf-token-expired':
            'フォーム送信が一定時間を過ぎたので、セキュリティー保護のため、もう一度やり直してください',
          'session-expired': 'セッションの有効期限切れです。<br>お手数ですがログインからやり直してください',
          'user-is-locked': 'アカウントがロックされています。<br>サポートに連絡してください',
          'wrong-credentials':
            'ユーザー名またはパスワードが間違います。<br>入力した情報を確認して再度ログインしてください',
        },
        email: 'メール',
        login: 'ログイン',
        password: 'パスワード',
        username: 'ユーザー名',
      },
    },
    events: {
      copyToClipboardSuccess: 'コピーしました',
      copyToClipboardFailure: 'コピー出来ませんでした',
    },
  },
};
