
import Vue from 'vue';
import { VBtn } from 'vuetify/lib';

/**
 * Sub 'class' of {@link VBtn} that adds some styles and changes default values to adhere closer to the 'mt' theme
 */
export default Vue.extend({
  extends: VBtn,
  props: {
    // Make depressed the default style
    depressed: {
      type: Boolean,
      default: true,
    },
    // Disable ripple by default
    ripple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        // Pretty much a call to 'super' to add required classes
        /*
         * TODO: Find the type that holds options
         *   2021-03-24 by: @maragon
         */
        ...(VBtn as any).options.computed.classes.call(this),
        'mt-button': true,
      };
    },
  },
});
