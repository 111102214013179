import { provide, inject, InjectionKey } from 'vue';
import VueI18n from 'vue-i18n';

const injectionKey = Symbol('i18n') as InjectionKey<VueI18n>;

export function provideI18n(i18n: VueI18n): void {
  return provide(injectionKey, i18n);
}

/**
 * Composition function that returns VueI18n
 */
function useI18n(): VueI18n {
  return inject(injectionKey, new VueI18n());
}

export default useI18n;
