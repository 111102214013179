import { UnhandledRejectionHandler } from '@/services/errors/unhandledRejectionHandler';
import { PromiseRejectionEventHandler } from '@/services/errors/PromiseRejectionEventHandler';

export type Config = {
  promiseRejectionEventHandlers: PromiseRejectionEventHandler[];
};

export function delegateUnhandledRejectionHandler(config: Config): UnhandledRejectionHandler {
  return (event) => {
    const handler = config.promiseRejectionEventHandlers.find((handler) => handler.canHandle(event, event.reason));
    if (handler) {
      handler.handle(event, event.reason);
    } else {
      console.error('Unhandled promise rejection error:', event.reason);
    }
    return true;
  };
}
