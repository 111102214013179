import { provide, inject, InjectionKey } from 'vue';
import * as MessageService from '@/components/MtMessages/MessageService';

const messageServiceInjectionKey = Symbol('messageService') as InjectionKey<MessageService.MessageService>;

export function provideMessageService(messageService: MessageService.MessageService): void {
  return provide(messageServiceInjectionKey, messageService);
}

/**
 * Composition function that returns MessageService
 */
function useMessageService(): MessageService.MessageService {
  return inject(messageServiceInjectionKey, new MessageService.SimpleMessageService());
}

export default useMessageService;
