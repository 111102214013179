import { ref, Ref } from 'vue';
import { useResizeObserver, MaybeElement } from '@vueuse/core';

/**
 * Composition function that returns a ref of the DOM Rect of provided element ref.
 * The function will update the returned ref with a new DOM Rect when the element resizes.
 */
function useDomRect(element$: Ref<MaybeElement>): Ref<DOMRectReadOnly | null> {
  const domRect$ = ref<DOMRectReadOnly | null>(null);

  useResizeObserver(element$, ([entry]) => {
    if (entry) {
      domRect$.value = entry.contentRect;
    }
  });

  return domRect$;
}

export default useDomRect;
