import { PromiseRejectionEventHandler } from '@/services/errors/PromiseRejectionEventHandler';
import VueRouter, { Route } from 'vue-router';

interface RouterError extends Error {
  _isRouter: boolean;
  to: Route;
  from: Route;
  type: number;
}

/**
 * @see NavigationFailureType
 */
export class NavigationDuplicatedErrorHandler implements PromiseRejectionEventHandler<RouterError> {
  canHandle(event: PromiseRejectionEvent, reason: unknown): reason is RouterError {
    return (
      reason instanceof Error &&
      (reason as RouterError)._isRouter &&
      (reason as RouterError).type === VueRouter.NavigationFailureType.duplicated
    );
  }

  handle(): void {
    // Swallow the error
  }
}
