/**
 * Given a transaction partition range such as `NOW-3MONTHS,NOW` returns the number of months which can be queried.
 *
 * @param partitionRange
 * @returns
 */
export function getTransactionExportMaxMonths(partitionRange: string): number {
  const match = partitionRange.match(/^NOW-(\d+)MONTHS,NOW$/);
  if (!match) {
    return 0;
  }
  return Number(match[1]) || 0;
}
