import { getCurrentInstance } from 'vue';

// This type allows us to either produce a function that accepts one or two parameters depending on the type of value
// the event can emit
type EmitArguments<EVENTS, NAME extends keyof EVENTS & string> = EVENTS[NAME] extends undefined
  ? [NAME]
  : [NAME, EVENTS[NAME]];

interface EmitFn<EVENTS extends Record<string, unknown | undefined>> {
  <NAME extends keyof EVENTS & string>(...args: EmitArguments<EVENTS, NAME>): void;
}

/**
 * Composition fn that adds type safety to Event emission
 *
 * Based on: https://dev.to/3vilarthas/vue-js-typed-emits-2h87
 */
export function useEmitter<EVENTS extends Record<string, unknown | undefined>>(): EmitFn<EVENTS> {
  const instance = getCurrentInstance();

  if (instance === null) throw new Error(`'useEmitter' was called outside of a component's context`);

  return function emit<NAME extends keyof EVENTS & string>(...args: EmitArguments<EVENTS, NAME>): void {
    /*
     * TODO: Find why this is retrieving old typing information instead of the new signature
     *   2021-02-26 by: @maragon
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    instance.proxy.$emit(args[0], args[1]);
  };
}
